// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "book/img1.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img2: file(relativePath: { eq: "book/img2.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img3: file(relativePath: { eq: "book/img3.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img4: file(relativePath: { eq: "book/img4.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img5: file(relativePath: { eq: "book/img5.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img6: file(relativePath: { eq: "book/img6.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    img7: file(relativePath: { eq: "book/img7.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const resources = [
    { img: props.data.img1 },
    { img: props.data.img2 },
    { img: props.data.img3 },
    { img: props.data.img4 },
    { img: props.data.img5 },
    { img: props.data.img6 },
    { img: props.data.img7 },
  ]

  const sliderConf = {
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
    customPaging: (i) => {
      return (
        <a aria-label={`Go to slide ${i + 1}`} role="link">
          <GatsbyImage image={getImage(resources[i].img)} />
        </a>
      )
    },
    dotPosition: 'bottom',
  }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this book</h1>
      <p className="dropcap">
        <span>T</span>here are three main steps in the Yoga of Sri Aurobindo.
        (1) To establish our consciousness in our heart and to realise our
        individual soul and our Psychic Being. (2) To establish our
        consciousness also above our head, and to receive from the higher planes
        above us. (3) Once our consciousness is situated in these two places, to
        live the higher, Divine life.
      </p>
      <p className="text-indent">
        People who practice Sri Aurobindo’s Yoga are told that everything in
        their life and their yoga practice will improve when they find their
        Psychic Being and realize their soul.
      </p>
      <p className="text-indent">
        Above our head is our Jivatman. The word Jivatman is made up of two
        Sanskrit words; Jiva, the individual person, and Atman, the great Soul.
        This is the Supreme’s direct delegate in the human life. It is there
        throughout all of our lives. It is uncreated, undying and unborn. Our
        Jivatman puts a portion of itself into our human heart to be our
        individual soul. Our soul goes through the processes of birth, death and
        rebirth. Because our individual soul is a pure part of the Supreme
        Consciousness, it is always conscious of its Divine origin. This is what
        inspires and guides us to grow in consciousness until all of our being
        is conscious of the Divine.
      </p>
      <p className="text-indent">
        The word “Psychic” comes from “Psyche”, the Greek word for the soul. Sri
        Aurobindo and Mother used it to refer the individual soul in order not
        to confuse it with the great Soul.
      </p>
      <p className="text-indent">
        The Psychic Being at its origin is only a spark of the divine
        consciousness. Out of this spark will progressively emerge an
        independent conscious being, having its own view of the world and its
        own action and will. It is built up as a conscious individuality through
        successive lives. Eventually it becomes sufficiently developed to
        participate in the process.
      </p>
      <p className="text-indent">
        Our soul contains all the highest qualities recognized by all religions
        and philosophies, such as love, kindness, tenderness, goodness, wisdom,
        patience, tolerance, generosity, etc. Over innumerable lifetimes, our
        soul forms and organizes our Psychic Being around itself. It does this
        by putting its high divine qualities into our more outer parts; into all
        the parts and functions of our mind; into all aspects of our vital (our
        emotions, life impulses and feelings) and also into our physical body.
        Then they are “psychisized”. Eventually all our psychisized parts are
        vibrating at the same rate and cooperating in the same way, and this
        makes a organized, integrated inner being, formed around our immortal
        soul. Sri Aurobindo and Mother call this inner being our Psychic Being.
      </p>
      <p className="text-indent">
        We die when our physical body can no longer support life. Then our
        various parts, mental, vital and physical, return to their origins. Our
        body disintegrates into the earth elements which formed it, our thoughts
        return to the universal mental planes, and our feelings and energies
        return to the universal vital planes. Our soul goes to take rest in the
        world-soul at the center of creation, where it assimilates the
        experiences of that lifetime, prepares for rebirth, and chooses the
        circumstances of its next birth.
      </p>
      <p className="text-indent">
        When our Psychic Being is sufficiently developed and organised, its
        respective psychisized parts remain organised around our soul as our
        Psychic Being and stay with our soul between lifetimes. There comes a
        point where a Psychic Being is so developed and so conscious that at
        death it has the ability and the power to choose where it will go, and
        whether it will take birth or not. This is seen in the history of the
        world’s great spiritual figures.
      </p>
      <p className="text-indent">
        The book, Finding The Psychic Being, contains Sri Aurobindo’s and
        Mother’s teachings with different information about the individual soul
        and the Psychic Being. People can use it to find their Psychic Being.
      </p>
      <p className="text-indent">
        <Link to="/introduction">Continue reading...</Link>
      </p>
      <h2>About Integral Yoga Talks</h2>
      <p className="dropcap">
        <span>T</span>his book is part of the Integral Yoga Talks project. The
        Integral Yoga Talks project is a website offering which will publish
        talks by Loretta Shartsis on Sri Aurobindo and Mother, both in audio and
        video, and will also make available Loretta’s book compilations as
        web-books. The project is scheduled to be released in stages from
        2022–24 under the umbrella of Prisma’s new publications format of
        gift-economy publishing.
      </p>
      <p className="text-indent">
        Over the years, Loretta has given public talks on Sri Aurobindo and
        Mother both in Auroville and abroad, and has recorded two series of
        talks for Auroville Radio. She has published four book-compilations of
        their works. These on-going efforts will now be available worldwide on
        the website{' '}
        <Link to="https://www.integral-yoga-talks.com/en/">
          www.integral-yoga-talks.com
        </Link>
        .
      </p>
      <h2>About Loretta Shartsis</h2>
      <p className="dropcap">
        <span>L</span>oretta Shartsis came to the Sri Aurobindo Ashram in
        February 1972, because she wanted to meet Mother. It was Sri Aurobindo’s
        100th Centenary year. Loretta met Mother in March and the next day,
        without knowing how it happened, she found herself living at the centre
        of Auroville, with her own room in the Matrimandir Worker’s Camp. From
        that time, her life has been devoted to Mother and Sri Aurobindo. Fifty
        years later, for Sri Aurobindo’s 150th Birth Centenary, the world will
        have the results of her dedicated teaching about Mother and Sri
        Aurobindo.
      </p>
      <p className="text-indent">
        Loretta came to Mother after growing up with a life of work and
        education as a visual artist. Later she became an attorney, doing
        Supreme Court and Court of Appeals Research and Presentation and court
        trials. In her teaching work she combines a life of aesthetic pursuits
        and the high quality research and public presentation required in a
        court of law.
      </p>
      <h2>The print book</h2>
      <div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>{about.altTitle}</i> at
        a fixed price. The author and the publisher have also chosen to offer
        the book to read online without a pay-wall or advertisement. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. It is an invitation to give what feels right for the value you've
        received from the book and/or the support you want to give us to
        continue doing our work. Publishing in this manner is an experiment in
        abundance where we trust that when we give, we will also receive. We
        want you to enjoy the book, learn from the book, but also support the
        book. If it was worth your time, please consider buying the book or
        contributing.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p className="text-indent">
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
